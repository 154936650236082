


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/entities/User';
import UserAvatar from '@/views/profile/components/UserAvatar.vue';

@Component({
  components: { UserAvatar },
})
export default class TeachersList extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  teachers!: User[];

  @Prop({
    type: Number,
    required: true,
  })
  courseId!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  showEditBtn!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showAvatar!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  col!: boolean;
}
