










































































































































































import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import { VCalendar } from '@/types/VCalendar';
import { ApiFacade } from '@/services/ApiFacade';
import { Polygon } from '@/models/entities/Polygon';
import LoadingContent from '@/components/LoadingContent.vue';
import { Course } from '@/models/entities/Course';
import { CalendarTimestamp } from 'vuetify';
import ScheduleTime from '@/views/courses/components/ScheduleTime.vue';
import { Lesson } from '@/models/entities/Lesson';
import { TimeAsString } from '@/types/CommonTypes';
import TeachersList from '@/views/courses/components/TeachersList.vue';
import { AppStore } from '@/store/types/AppStore';
import { LessonStatus } from '@/types/Statuses';

interface PolygonScheduleView {
  $refs: {
    calendar: VCalendar;
  };
}

interface UpdateRangeParams {
  start: CalendarTimestamp;
  end: CalendarTimestamp;
}

interface CalendarEvent {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
  lesson: Lesson;
  course: Course;
  startTime: TimeAsString;
  endTime: TimeAsString;
}

@Component({
  components: {
    TeachersList,
    ScheduleTime,
    LoadingContent,
  },
})
class PolygonScheduleView extends Vue {
  @Inject() apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  @InjectReactive() polygon!: Polygon;

  isLoading: boolean = false;

  calendarType: 'month' | 'week' | 'year' = 'month';
  value: string = '';
  colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'blue'];
  private courseId2Color: Record<number, string> = {};
  private colorsOccupied: Record<string, number> = {};

  events: CalendarEvent[] = [];
  weekdays = [1, 2, 3, 4, 5, 6, 0];

  courses: Course[] = [];
  private minDate: Date | null = null;
  private maxDate: Date | null = null;

  async mounted() {
    this.setToday();
    await this.initialize();
  }

  get isPolygonAdmin(): boolean {
    return Boolean(this.appStore.getters.profile
      && (this.appStore.getters.profile.isSuperAdmin || this.polygon.isUserAdmin(this.appStore.getters.profile)));
  }

  get coursesInDiapason(): Course[] {
    if (!this.minDate || !this.maxDate) {
      return this.courses;
    }
    const courses: Set<Course> = this.events
      .filter((e) => {
        return this.minDate && this.maxDate
          && e.lesson.lessonDateDt >= this.minDate && e.lesson.lessonFinishDt <= this.maxDate;
      })
      .reduce((r, e) => {
        return r.add(e.course);
      }, new Set<Course>());
    return [...courses];
  }

  getCategory(event: CalendarEvent) {
    return event.color;
  }

  getEventColor(event: CalendarEvent) {
    return event.color;
  }

  setToday() {
    this.value = dayjs()
      .format('YYYY-MM-DD');
  }

  prevPeriod() {
    this.$refs.calendar.prev();
  }

  nextPeriod() {
    this.$refs.calendar.next();
  }

  @Watch('polygon')
  async initialize() {
    this.isLoading = true;
    try {
      this.courses = await this.apiFacade.fetchCourses({
        polygonId: this.polygon.polygonId,
        includeUsers: true,
      });
      this.initializeEvents();
    } finally {
      this.isLoading = false;
    }
  }

  initializeEvents() {
    const events: CalendarEvent[] = this.courses
      ?.flatMap((c) => {
        return c.activeLessons.map((l) => {
          return {
            lesson: l,
            course: c,
          };
        });
      })
      .map((cl) => {
        const name = `${cl.course.courseName} / ${cl.lesson.lessonDurationHumanized}`;
        return {
          name,
          start: cl.lesson.lessonDateDt,
          end: cl.lesson.lessonFinishDt,
          lesson: cl.lesson,
          course: cl.course,
          startTime: cl.lesson.lessonStartTime,
          endTime: cl.lesson.lessonFinishTime,
          color: this.courseColor(cl.course),
          timed: true,
        };
      }) || [];

    this.events = events.filter((e) => {
      return e.lesson.status === LessonStatus.active;
    });
  }

  updateRange({
    start,
    end,
  }: UpdateRangeParams) {
    // const events = [];
    const min = new Date(`${start.date}T00:00:00`);
    const max = new Date(`${end.date}T23:59:59`);
    this.minDate = min;
    this.maxDate = max;
    // todo: filter courses in `legend` block by minmax
    // console.log('min,max:', min, max);
  }

  handleClickEvent(event: { event: CalendarEvent }) {
    console.log(event);
  }

  handleDialogClose() {
    this.$router.push({
      name: 'polygon-schedule',
      params: { polygonId: String(this.polygon.polygonId) },
    });
  }

  handleCourseUpdated() {
    this.initialize();
  }

  courseColor(course: Course) {
    if (!this.courseId2Color[course.courseId]) {
      const baseColor = this.colors[course.courseId % this.colors.length];
      const courseColor = this.colorsOccupied[baseColor]
        ? `${baseColor} darken-${this.colorsOccupied[baseColor]}`
        : baseColor;
      this.courseId2Color[course.courseId] = courseColor;
      this.colorsOccupied[baseColor] = (this.colorsOccupied[baseColor] || 1) + 1;
    }
    return this.courseId2Color[course.courseId];
  }

  lessonsAfterDate(course: Course, date: Date): number {
    return course.activeLessons.filter((l) => {
      return l.lessonDateDt >= date;
    }).length;
  }

  isEditAllowed(course: Course): boolean {
    const user = this.appStore.getters.profile;
    if (!user) {
      return false;
    }
    return Boolean(user.isSuperAdmin || course.isCourseAdmin(user));
  }
}

export default PolygonScheduleView;
