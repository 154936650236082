



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/entities/User';

@Component({})
export default class UserAvatar extends Vue {
  @Prop({
    required: true,
  })
  user!: User;

  @Prop({
    default: 32,
  })
  size!: number | string;

  get bgColor() {
    return this.user.avatarUrl ? '' : 'pink darken-2';
  }
}
